import React, { useState } from 'react';
import axios from 'axios';
import './ManageSections.css';

function ManageSections() {
    const [selectedCollection, setSelectedCollection] = useState('');
    const [sections, setSections] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // New state for search input
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const collectionLabels = {
        decisions: 'القرارات',
        laws: 'القوانين',
        Decrees: 'المراسيم',
        LegislativeDecrees: 'المراسيم التشريعية',
        radios: 'الاذاعات',
        newsletters: 'النشرات',
        advertisements: 'الاعلانات',
        OfficialAnnouncements: 'الاعلانات الرسمية',
        judicialDecisions: 'الاحكام القضائية',
        assemblyMemoirs: 'مذكرات مجلس الشعب',
        Circulars: 'التعاميم'
    };

    const fetchSections = async (collectionName) => {
        setLoading(true);
        setMessage('');
        try {
            const res = await axios.get(`https://node1.syriaonrecord.com/sections/${collectionName}`);
            setSections(res.data);
            setMessage('');
        } catch (error) {
            console.error('Error fetching sections:', error);
            setMessage('Error fetching sections.');
            setSections([]);
        }
        setLoading(false);
    };

    const deleteSection = async (sectionId) => {
        if (window.confirm('Are you sure you want to delete this section?')) {
            try {
                await axios.delete(`https://node1.syriaonrecord.com/sections/${selectedCollection}/${sectionId}`);
                setSections(sections.filter(section => section._id !== sectionId));
                setMessage('Section deleted successfully.');
            } catch (error) {
                console.error('Error deleting section:', error.response ? error.response.data : error.message);
                setMessage('Error deleting section.');
            }
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
    };

    // Filter sections based on the search query
    const filteredSections = sections.filter((section) => {
        const lowercasedQuery = searchQuery.toLowerCase();
        return (
            section._id.toLowerCase().includes(lowercasedQuery) ||
            section.name.toLowerCase().includes(lowercasedQuery) ||
            section.ministry.toLowerCase().includes(lowercasedQuery)
        );
    });

    return (
        <div className="ManageSections">
            <h1>Manage Sections</h1>
            <select
                value={selectedCollection}
                onChange={(e) => {
                    setSelectedCollection(e.target.value);
                    fetchSections(e.target.value);
                }}
            >
                <option value="">اختر النوع</option>
                {Object.keys(collectionLabels).map((key) => (
                    <option key={key} value={key}>
                        {collectionLabels[key]}
                    </option>
                ))}
            </select>
            <input
                type="text"
                placeholder="Search by ID, Name, or Ministry"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input" // Add appropriate styles in ManageSections.css
            />
            {loading && <p>Loading...</p>}
            <p>{message}</p>
            {filteredSections.length > 0 && (
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>اسم القرار</th>
                                <th>وصف القرار</th>
                                <th>التاريخ</th>
                                <th>الوزارة</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredSections.map((section) => (
                                <tr key={section._id}>
                                    <td>{section.name}</td>
                                    <td>{section.description}</td>
                                    <td>{formatDate(section.date)}</td>
                                    <td>{section.ministry}</td>
                                    <td>
                                        <button onClick={() => deleteSection(section._id)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            {filteredSections.length === 0 && !loading && <p>No matching sections found.</p>}
        </div>
    );
}

export default ManageSections;
