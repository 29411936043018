import React, { useState } from 'react';
import AdminLogin from './adminlogin';
import UploadPage from './UploadPage';
import StorePage from './StorePage';
import UserList from './UserList';
import TopBar from './TopBar';
import SectionsTable from './SectionsTable';
import ManageSections from './ManageSections';
import FilesPage from './FilesPage';
import './App.css';

function App() {
    const [currentPage, setCurrentPage] = useState('store');
    const [admin, setAdmin] = useState(null); // State to track if admin is logged in
    const [selectedFile, setSelectedFile] = useState(''); // State to track the selected file

    const handleLogin = (adminData) => {
        setAdmin(adminData); // Set the admin data when login is successful
    };

    const handleLogout = () => {
        setAdmin(null); // Clear admin data on logout
        setCurrentPage('login'); // Redirect to login page after logout
    };

    // Handler for file selection in FilesPage
    const handleFileSelect = (file) => {
        setSelectedFile(file); // Set the selected file
        setCurrentPage('upload'); // Navigate to UploadPage
    };

    return (
        <div className="App">
            {/* Conditionally render TopBar */}
            {admin && <TopBar />}
          
            {!admin ? (
                <AdminLogin onLogin={handleLogin} />
            ) : (
                <>
                    <button 
                        className={currentPage === 'store' ? 'active' : ''}
                        onClick={() => setCurrentPage('store')}
                    >
                        تخزين الملفات في السيرفر
                    </button>

                    <button 
                        className={currentPage === 'file' ? 'active' : ''}
                        onClick={() => setCurrentPage('file')}
                    >
                        الملفات المخزنة
                    </button>

                    <button 
                        className={currentPage === 'upload' ? 'active' : ''}
                        onClick={() => setCurrentPage('upload')}
                    >
                        تخزين البيانات
                    </button>
                    <button 
                        className={currentPage === 'sections' ? 'active' : ''}
                        onClick={() => setCurrentPage('sections')}
                    >
                        قاعدة البيانات
                    </button>
                    <button 
                        className={currentPage === 'manageSections' ? 'active' : ''}
                        onClick={() => setCurrentPage('manageSections')}
                    >
                        إدارة البيانات
                    </button>
                    <button 
                        className={currentPage === 'users' ? 'active' : ''}
                        onClick={() => setCurrentPage('users')}
                    >
                        المشتركين
                    </button>
                    <button onClick={handleLogout}>خروج</button> {/* Logout button */}

                    {currentPage === 'upload' && <UploadPage selectedFile={selectedFile} />}
                    {currentPage === 'store' && <StorePage />}
                    {currentPage === 'file' && <FilesPage onFileSelect={handleFileSelect} />}
                    {currentPage === 'sections' && <SectionsTable />}
                    {currentPage === 'users' && <UserList />}
                    {currentPage === 'manageSections' && <ManageSections />}
                </>
            )}
        </div>
    );
}

export default App;

