import React, { useState } from 'react';
import axios from 'axios';

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Correct loading state

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
    setMessage(''); // Clear message on new file selection
  };

  const onFileUpload = async () => {
    if (!file) {
      setMessage('Please select a file first.');
      console.log('No file selected for upload.');
      return;
    }
  
    console.log('File selected for upload:', file.name, 'Size:', file.size, 'Type:', file.type);
  
    const formData = new FormData();
    formData.append('file', file);
  
    // Log FormData contents to ensure the file is included
    console.log('FormData constructed, logging its contents:');
    for (let pair of formData.entries()) {
      console.log('FormData Key:', pair[0], 'FormData Value:', pair[1]);
    }
  
    setIsLoading(true); // Set loading state before the upload
    console.log('Uploading file...');
  
    try {
      const res = await axios.post('https://node1.syriaonrecord.com/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('Server response:', res.data);
      setMessage(res.data.message);
    } catch (err) {
      console.error('Error during file upload:', err);  // Log any errors from the server
      setMessage('File upload failed.');
    } finally {
      setIsLoading(false); // Reset loading state after the upload completes
      console.log('File upload process completed.');
    }
  };
  
  return (
    <div>
      <h2>Upload a Word File</h2>
      
      <input type="file" onChange={onFileChange} accept=".doc,.docx" />
      <button onClick={onFileUpload} disabled={isLoading}>
        {isLoading ? 'Uploading...' : 'Upload'}
      </button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default FileUpload;
