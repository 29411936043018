import React, { useEffect, useState } from 'react';
import axios from 'axios';

function FilesPage({ onFileSelect }) {
    const [files, setFiles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filesPerPage] = useState(10); // Number of files per page
    const [message, setMessage] = useState('');

    // Fetch files from the backend
    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const res = await axios.get('https://node1.syriaonrecord.com/files'); // Adjust API endpoint
                setFiles(res.data.files); // Assuming the response contains a 'files' array
            } catch (error) {
                console.error('Error fetching files:', error);
                setMessage('Error fetching files.');
            }
        };

        fetchFiles();
    }, []);

    // Calculate the files to display on the current page
    const indexOfLastFile = currentPage * filesPerPage;
    const indexOfFirstFile = indexOfLastFile - filesPerPage;
    const currentFiles = files.slice(indexOfFirstFile, indexOfLastFile);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <h1>الملفات المخزنة</h1>
            {message && <p>{message}</p>}

            {files.length > 0 ? (
                <>
                    <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>اسم الملف</th>
                                <th>الإجراء</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentFiles.map((file, index) => (
                                <tr key={index}>
                                    <td>{indexOfFirstFile + index + 1}</td>
                                    <td>{file}</td>
                                    <td>
                                        <button onClick={() => onFileSelect(file)}>تحديد</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Pagination Controls */}
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                        {Array.from({ length: Math.ceil(files.length / filesPerPage) }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => paginate(index + 1)}
                                style={{
                                    margin: '0 5px',
                                    padding: '5px 10px',
                                    backgroundColor: currentPage === index + 1 ? '#007bff' : '#fff',
                                    color: currentPage === index + 1 ? '#fff' : '#000',
                                    border: '1px solid #007bff',
                                    cursor: 'pointer',
                                }}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            ) : (
                <p>No files available.</p>
            )}
        </div>
    );
}

export default FilesPage;
