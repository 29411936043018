// UserList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './UserList.css'; // Optional: Add CSS for styling

function UserList() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        // Fetch the list of users from the backend when the component mounts
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://node1.syriaonrecord.com/users'); // Update with your API endpoint
                setUsers(response.data.users);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    return (
        <div className="UserList">
            <h2>Registered Users</h2>
            {users.length > 0 ? (
                <ul>
                    {users.map((user) => (
                        <li key={user.id}>
                            <p><strong>Name:</strong> {user.name}</p>
                            <p><strong>Email:</strong> {user.email}</p>
                            <p><strong>Registration Date:</strong> {new Date(user.createdAt).toLocaleDateString('en-GB')}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No users found.</p>
            )}
        </div>
    );
}

export default UserList;
