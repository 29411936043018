import React, { useState } from 'react';
import axios from 'axios';
import TopBar from './TopBar'; // Import TopBar component
import './adminlogin.css';

function AdminLogin({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(''); // Reset any previous errors
    
        
    
        try {
            const response = await axios.post('https://node1.syriaonrecord.com/admin/login', {
                username,
                password,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (response.data.success) {
                onLogin(response.data.admin); // Pass admin data to parent component
            } else {
                setError('Invalid username or password');
            }
        } catch (error) {
            console.error('Error during login:', error);
            setError(error.response?.data?.message || 'An error occurred during login.');
        }
    };
    

    return (
        <div>
            <TopBar /> {/* Include TopBar component */}
            <div className="AdminLogin">
                <h2>Admin Login</h2>
                {error && <p className="error">{error}</p>}
                <form onSubmit={handleLogin}>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Login</button>
                </form>
            </div>
        </div>
    );
}

export default AdminLogin;
