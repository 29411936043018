import React, { useState , useEffect} from 'react';
import axios from 'axios';
import './UploadPage.css';
import Box from '@mui/material/Box';

function UploadPage({ selectedFile }) {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [text, setText] = useState('');
    const [sections, setSections] = useState([]);
    const [selectedText, setSelectedText] = useState('');
    const [currentSelection, setCurrentSelection] = useState('');
    const [namePersons, setNamePersons] = useState([]);
    const [namePersonsFather, setNamePersonsFather] = useState([]);
    const [namePersonsMother, setNamePersonsMother] = useState([]);
    const [namePersonsBDay, setNamePersonsBDay] = useState([]);
    const [namePersonsCity, setNamePersonsCity] = useState([]);
    const [namePersonsAddress, setNamePersonsAddress] = useState([]);
    const [namePersonsNumber, setNamePersonsNumber] = useState([]);
    const [nameCompanys, setNameCompanys] = useState([]);
    const [nameCompanysAddress, setNameCompanysAddress] = useState([]);
    const [nameCompanysADay, setNameCompanysADay] = useState([]);
    const [nameCompanysSpecific, setNameCompanysSpecific] = useState([]);
    const [sectionName, setSectionName] = useState('');
    const [sectionDescription, setSectionDescription] = useState('');
    const [part, setPart] = useState('');
    const [date, setDate] = useState('');
    const [ministry, setMinistry] = useState('');
    const [collectionName, setCollectionName] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedSectionType, setSelectedSectionType] = useState('');
    const [filesFromBackend, setFilesFromBackend] = useState([]);
    const [selectedFileFromBackend, setSelectedFileFromBackend] = useState('');
    
    
    
    
    
    // Fetch available files from the backend
    useEffect(() => {
        if (selectedFile) {
            setFile(selectedFile); // Update file if new file is passed
        }
    }, [selectedFile]);

    

    // New state for custom options
    const [customPart, setCustomPart] = useState('');
    const [customMinistry, setCustomMinistry] = useState('');
    const [partOptions, setPartOptions] = useState(['مديرية الجمارك العامة','المديرية العامة للآثار والمتاحف','الجهاز المركزي للرقابة المالية','المجلس الأعلى للتعليم التقاني', 'فرع الهجرة و الجوازات','مديرية الزراعة والإصلاح الزراعي باللاذقية','مديرية الشؤون الاجتماعية والعمل','إدارة الهجرة والجوازات','الجهاز المركزي للرقابة المالية','قوى الامن الداخلي', 'آخر']);
    const [ministryOptions, setMinistryOptions] = useState([
        'وزارة المالية',
        'رئاسة مجلس الوزراء',
         'وزارة الداخلية',
         'وزارة الثقافة',
         'رئاسة الجمهورية',
         'وزارة الصحة',
         'وزارة الشؤون الاجتماعية والعمل',
          'وزارة التجارة الداخلية',
          'وزارة التعليم العالي',
          'وزارة الداخلية',
           'وزارة الإدارة المحلية و البيئة',
           'وزارة النفط و الثروة المعدنية',
           'وزارة الأشغال العامة والإسكان',
           'وزارة الزراعة والإصلاح الزراعي',
           'وزارة التعليم العالي و البحث العلمي',
           'وزارة الصناعة',
           'وزارة التجارة الداخلية وحماية المستهلك',
           'وزارة الأشغال العامة والإسكان',
            'أخر']);

    // State to handle visibility of the text inputs for adding new options
    const [showCustomPartInput, setShowCustomPartInput] = useState(false);
    const [showCustomMinistryInput, setShowCustomMinistryInput] = useState(false);

    const addNewPart = () => {
        if (customPart && !partOptions.includes(customPart)) {
            setPartOptions([...partOptions, customPart]);
            setPart(customPart);
            setCustomPart('');
            setShowCustomPartInput(false); // Hide the input after adding
        }
    };

    const addNewMinistry = () => {
        if (customMinistry && !ministryOptions.includes(customMinistry)) {
            setMinistryOptions([...ministryOptions, customMinistry]);
            setMinistry(customMinistry);
            setCustomMinistry('');
            setShowCustomMinistryInput(false); // Hide the input after adding
        }
    };


    const onFileUpload = async () => {
        if (!file) {
            setMessage('Please select a file from the list.');
            return;
        }

        try {
            const res = await axios.post(`https://node1.syriaonrecord.com/upload-from-backend`, {
                fileName: file,
            }, {
                headers: { 'Content-Type': 'application/json' },
            });
            setText(res.data.text);
            setMessage(res.data.message);
        } catch (error) {
            console.error('Error uploading file:', error);
            setMessage('Error processing the selected file.');
        }
    };


    const handleExtractedTextMouseUp = () => {
        const selected = window.getSelection().toString();
        if (selected) {
            setSelectedText(selected);
        }
    };

    const handleSelectedTextMouseUp = () => {
        const selected = window.getSelection().toString();
        if (selected) {
            setCurrentSelection(selected);
            setShowDropdown(true);
        }
    };

    const handleDropdownSelection = (option) => {
        setShowDropdown(false);
        switch (option) {
            case 'name':
                setSectionName(currentSelection);
                break;
            case 'description':
                setSectionDescription(currentSelection);
                break;
            case 'namePersons':
                if (!namePersons.includes(currentSelection)) {
                    setNamePersons([...namePersons, currentSelection]);
                }
                break;
            case 'namePersonsFather':
                if (!namePersonsFather.includes(currentSelection)) {
                    setNamePersonsFather([...namePersonsFather, currentSelection]);
                }
                break;
            case 'namePersonsMother':
                if (!namePersonsMother.includes(currentSelection)) {
                    setNamePersonsMother([...namePersonsMother, currentSelection]);
                }
                break;
            case 'namePersonsBDay':
                if (!namePersonsBDay.includes(currentSelection)) {
                    setNamePersonsBDay([...namePersonsBDay, currentSelection]);
                }
                break;
            case 'namePersonsCity':
                if (!namePersonsCity.includes(currentSelection)) {
                    setNamePersonsCity([...namePersonsCity, currentSelection]);
                }
                break;    
            case 'namePersonsAddress':
                if (!namePersonsAddress.includes(currentSelection)) {
                    setNamePersonsAddress([...namePersonsAddress, currentSelection]);
                } 
                break;
            case 'namePersonsNumber':
                    if (!namePersonsNumber.includes(currentSelection)) {
                        setNamePersonsNumber([...namePersonsNumber, currentSelection]);
                    } 
                    break; 
            case 'nameCompanys':
                    if (!nameCompanys.includes(currentSelection)) {
                        setNameCompanys([...nameCompanys, currentSelection]);
                    } 
                    break;  
            case 'nameCompanysAddress':
                    if (!nameCompanysAddress.includes(currentSelection)) {
                        setNameCompanysAddress([...nameCompanysAddress, currentSelection]);
                    } 
                    break;
            case 'nameCompanysADay':
                    if (!nameCompanysADay.includes(currentSelection)) {
                        setNameCompanysADay([...nameCompanysADay, currentSelection]);
                    } 
                    break;
            case 'nameCompanysSpecific':
                    if (!nameCompanysSpecific.includes(currentSelection)) {
                        setNameCompanysSpecific([...nameCompanysSpecific, currentSelection]);
                    } 
                    break;                
            default:
                break;
        }
    };

    const addSection = () => {
        setSections([...sections, {
            type: selectedSectionType,
            name: sectionName,
            description: sectionDescription,
            namePersons,
            namePersonsFather,
            namePersonsMother,
            namePersonsBDay,
            namePersonsCity,
            namePersonsAddress,
            namePersonsNumber,
            nameCompanys,
            nameCompanysAddress,
            nameCompanysADay,
            nameCompanysSpecific,
            part,
            date,
            ministry,
            text: selectedText
        }]);
        setSelectedText('');
        setCurrentSelection('');
        setNamePersons([]);
        setNamePersonsFather([]);
        setNamePersonsMother([]);
        setNamePersonsBDay([]);
        setNamePersonsCity([]);
        setNamePersonsAddress([]);
        setNamePersonsNumber([]);
        setNameCompanys([]);
        setNameCompanysAddress([]);
        setNameCompanysADay([]);
        setNameCompanysSpecific([]);
        setSectionName('');
        setSectionDescription('');
        setPart('');
        setDate('');
        setMinistry('');
        setSelectedSectionType('');
    };

    const saveSections = async () => {
        if (!collectionName) {
            setMessage('Please provide a collection name.');
            return;
        }

        try {
            await axios.post('https://node1.syriaonrecord.com/sections', {
                collectionName,
                sections,
            });
            setMessage('Sections saved to database.');
            setSections([]);
            setCollectionName('');
        } catch (error) {
            console.error('Error saving sections:', error);
            setMessage('Error saving sections.');
        }
    };

    const handleSectionTypeChange = (e) => {
        const selectedType = e.target.value;
        setSelectedSectionType(selectedType);
        setCollectionName(selectedType); 
    };

    // Clear namePersons array
    const clearNamePersons = () => setNamePersons([]);
const clearNamePersonsFather = () => setNamePersonsFather([]);
const clearNamePersonsMother = () => setNamePersonsMother([]);
const clearNamePersonsBDay = () => setNamePersonsBDay([]);



    // Clear nameCompanys array
    const clearNameCompanys = () => setNameCompanys([]);
const clearNameCompanysAddress = () => setNameCompanysAddress([]);
const clearNameCompanysADay = () => setNameCompanysADay([]);
const clearNameCompanysSpecific = () => setNameCompanysSpecific([]);

    return (
        <div className="UploadPage">
            <div>
            <h1>تخزين البيانات</h1>
            <p>الملف المحدد: {file || 'لم يتم التحديد'}</p>
            <button onClick={onFileUpload}>معالجة الملف</button>
            {message && <p>{message}</p>}
           
        </div>


   
            
            
        
            {text && (
                <div className="text-container" onMouseUp={handleExtractedTextMouseUp}>
                    {text}
                </div>
            )}
            {selectedText && (
                <div>
                    <h2>النص المحدد</h2>
                    <div className="selected-text-container">
                        <textarea
                            value={selectedText}
                            readOnly
                            className="selected-text"
                            onMouseUp={handleSelectedTextMouseUp}
                        />
                        <button className="dropdown-button" onClick={() => setShowDropdown(!showDropdown)}>
                            ▼
                        </button>
                        {showDropdown && (
                            <ul className="dropdown-menu">
                                <li onClick={() => handleDropdownSelection('name')}>اسم النوع</li>
                                <li onClick={() => handleDropdownSelection('description')}>وصف النوع</li>
                                <li onClick={() => handleDropdownSelection('namePersons')}>اسم الشخص</li>
                                <li onClick={() => handleDropdownSelection('namePersonsFather')}>اسم الأب</li>
                                <li onClick={() => handleDropdownSelection('namePersonsMother')}>اسم الأم</li>
                                <li onClick={() => handleDropdownSelection('namePersonsBDay')}>تاريخ الولادة</li>
                                <li onClick={() => handleDropdownSelection('namePersonsCity')}>مكان الولادة</li>
                                <li onClick={() => handleDropdownSelection('namePersonsNumber')}>الرقم الوطني</li>
                                <li onClick={() => handleDropdownSelection('namePersonsAddress')}>العنوان</li>
                                <li onClick={() => handleDropdownSelection('nameCompanys')}>اسم الشركة</li>
                                <li onClick={() => handleDropdownSelection('nameCompanysAddress')}>عنوان الشركة</li>
                                <li onClick={() => handleDropdownSelection('nameCompanysADay')}>تاريخ تأسيس الشركة</li>
                                <li onClick={() => handleDropdownSelection('nameCompanysSpecific')}>المؤسسة الحكومية المعنية</li>
                            </ul>
                        )}
                    </div>
                    <select
                        value={selectedSectionType}
                        onChange={handleSectionTypeChange}
                    >
                        <option value="">اختر نوع التخزين</option>
                        <option value="decisions">القرارات</option>
                        <option value="laws">القوانين</option>
                        <option value="Decrees">المراسيم</option>
                        <option value="LegislativeDecrees">المراسيم التشريعية</option>
                        <option value="radios">الاذاعات</option>
                        <option value="newsletters">النشرات</option>
                        <option value="advertisements">الاعلانات</option>
                        <option value="OfficialAnnouncements">الاعلانات الرسمية</option>
                        <option value="judicialDecisions">الاحكام القضائية</option>
                        <option value="assemblyMemoirs">مذاكرات مجلس الشعب</option>
                        <option value="Circulars">التعاميم</option>
                    </select>
                    <input
                        type="text"
                        placeholder="اسم النوع"
                        value={sectionName}
                        onChange={(e) => setSectionName(e.target.value)}
                    />
                    <textarea
                        placeholder="وصف النوع"
                        value={sectionDescription}
                        onChange={(e) => setSectionDescription(e.target.value)}
                    />
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                    />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <select value={part} onChange={(e) => setPart(e.target.value)}>
                            <option value="">اختر القسم</option>
                            {partOptions.map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </select>
                        <button onClick={() => setShowCustomPartInput(!showCustomPartInput)}>+</button>
                    </div>
                    {showCustomPartInput && (
                        <div>
                            <input
                                type="text"
                                placeholder="إضافة قسم جديد"
                                value={customPart}
                                onChange={(e) => setCustomPart(e.target.value)}
                            />
                            <button onClick={addNewPart}>إضافة</button>
                        </div>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <select value={ministry} onChange={(e) => setMinistry(e.target.value)}>
                            <option value="">اختر الوزارة</option>
                            {ministryOptions.map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </select>
                        <button onClick={() => setShowCustomMinistryInput(!showCustomMinistryInput)}>+</button>
                    </div>
                    {showCustomMinistryInput && (
                        <div>
                            <input
                                type="text"
                                placeholder="إضافة وزارة جديدة"
                                value={customMinistry}
                                onChange={(e) => setCustomMinistry(e.target.value)}
                            />
                            <button onClick={addNewMinistry}>إضافة</button>
                        </div>
                    )}
                    <Box className="persons-container">
                        <h3>أسماء الأشخاص:</h3>
                        {namePersons.map((name, index) => (
                            <div key={index}>
                                <p>الاسم: {name}</p>
                                <p>اسم الأب: {namePersonsFather[index]}</p>
                                <p>اسم الأم: {namePersonsMother[index]}</p>
                                <p>تاريخ الولادة: {namePersonsBDay[index]}</p>
                                <p>مكان الولادة: {namePersonsCity[index]}</p>
                                <p>الرقم الوطني: {namePersonsNumber[index]}</p>
                                <p>العنوان: {namePersonsAddress[index]}</p>
                            </div>
                        ))}
                        <button onClick={clearNamePersons}>Clear Persons</button>
<button onClick={clearNamePersonsFather}>Clear Persons Father</button>
<button onClick={clearNamePersonsMother}>Clear Persons Mother</button>
<button onClick={clearNamePersonsBDay}>Clear Persons Birthday</button>
                    </Box>
                    <Box className="companys-container">
                        <h3>أسماء الشركات:</h3>
                        {nameCompanys.map((name, index) => (
                            <div key={index}>
                                <p>اسم الشركة: {name}</p>
                                <p>عنوان الشركة: {nameCompanysAddress[index]}</p>
                                <p>تاريخ تأسيس الشركة: {nameCompanysADay[index]}</p>
                                <p>المؤسسة الحكومية المعنية: {nameCompanysSpecific[index]}</p>
                            </div>
                        ))}
                        <button onClick={clearNameCompanys}>Clear Companies</button>
<button onClick={clearNameCompanysAddress}>Clear Company Address</button>
<button onClick={clearNameCompanysADay}>Clear Company Day</button>
<button onClick={clearNameCompanysSpecific}>Clear Company Specific</button>
                    </Box>
                   
                    <button onClick={addSection}>حفظ</button>
                </div>
            )}
            {sections.length > 0 && (
                <div>
                    <h2>Sections</h2>
                    <ul>
                        {sections.map((section, index) => (
                            <li key={index}>
                                <strong>{section.type}: {section.name}</strong>
                                <p>{section.description}</p>
                                <p>الأشخاص: {section.namePersons.join(', ')}</p>
                                <p>الشركات: {section.nameCompanys.join(', ')}</p>
                                <p>القسم: {section.part}</p>
                                <p>التاريخ: {section.date}</p>
                                <p>الوزارة: {section.ministry}</p>
                            </li>
                        ))}
                    </ul>
                    <input
                        type="text"
                        placeholder="Collection Name"
                        value={collectionName}
                        onChange={(e) => setCollectionName(e.target.value)}
                    />
                    <button onClick={saveSections}>تأكيد</button>
                </div>
            )}
        </div>
    );
}

export default UploadPage;
